import React, { useState } from 'react';
import { VirtualScroller } from 'primereact/virtualscroller';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Icon } from 'components';
import cx from 'classnames';
import styles from './styles.module.scss';
import IgnoredClustersDropdown from 'components/ignored-clusters-dropdown';
import { ClusterItem, IgnoredCluster } from 'types/clusterInterfaces';

interface ClusterListProps {
  filteredClustersDataList: ClusterItem[];
  ignoredClusters: ClusterItem[];
  handleMoveUpClick: (cluster: IgnoredCluster) => void;
  loadingClusterMoveUp: string | null;
  onClickCluster: (
    id: string,
  ) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleIgnoreClick: (cluster: ClusterItem) => void;
  loadingClusterIgnore: string | null;
}

const ClusterList: React.FC<ClusterListProps> = ({
  filteredClustersDataList,
  ignoredClusters,
  handleMoveUpClick,
  loadingClusterMoveUp,
  onClickCluster,
  handleIgnoreClick,
  loadingClusterIgnore,
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const sortedRes = filteredClustersDataList.sort(
    (a, b) => b.failedCount + b.errorCount - (a.failedCount + a.errorCount),
  );

  // Adding isDropdown flag to treat IgnoredClustersDropdown as a separate item in the VirtualScroller
  // This ensures the dropdown renders after all cluster items in the scrollable area.
  const itemsWithDropdown = [...sortedRes, { isDropdown: true }];

  return (
    <div className={cx(styles.dataListView)}>
      <div className={styles.viewListBox}>
        <div className="absolute">
          <VirtualScroller
            items={itemsWithDropdown}
            itemSize={55}
            autoSize
            itemTemplate={(opt, scrollOptions) => {
              // Check if this is the placeholder item for IgnoredClustersDropdown
              if (opt.isDropdown) {
                // Show the dropdown only if ignoredClusters have items
                if (ignoredClusters.length > 0) {
                  return (
                    <div className={cx(styles.ignoredClustersWrapper)}>
                      <div className={styles.divider} />
                      <IgnoredClustersDropdown
                        ignoredClusters={ignoredClusters}
                        onMoveUpClick={handleMoveUpClick}
                        loadingClusterMoveUp={loadingClusterMoveUp}
                      />
                    </div>
                  );
                }
                return null; // If there are no ignored clusters, return null
              }

              // Render regular cluster items
              return (
                <div
                  onClick={onClickCluster(opt?.value)}
                  className={cx(styles.itemList, styles.link, {
                    [styles.even]: scrollOptions.even,
                  })}
                  onMouseEnter={() => setHoveredItem(opt.label)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <h6 className={styles.name}>{opt.label}</h6>
                  <div className={styles.data}>
                    <div className={styles.item}>
                      <Icon
                        className={cx(styles.icon, styles.fail)}
                        size={'1.5rem'}
                        name="fail"
                      />
                      {opt?.failedCount}
                    </div>
                    <div className={styles.item}>
                      <Icon
                        className={cx(styles.icon, styles.error)}
                        size={'1.5rem'}
                        name="error"
                      />
                      {opt.errorCount}
                    </div>
                    {hoveredItem === opt.label && (
                      <div className={styles.ignoreItem}>
                        {loadingClusterIgnore === opt.label ? (
                          <div className={styles.loadingContent}>
                            <ProgressSpinner
                              style={{ width: '1.5rem', height: '1.5rem' }}
                            />
                          </div>
                        ) : (
                          <div className={styles.ignoreButtonContainer}>
                            <button
                              className={styles.ignoreButton}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleIgnoreClick(opt);
                              }}
                            >
                              <Icon
                                className={cx(styles.icon, styles.ignore, {
                                  [styles.fade]: hoveredItem === opt.label,
                                })}
                                size={'1.5rem'}
                                name="ignore"
                                style={{ position: 'absolute' }}
                              />
                            </button>
                            <span className={styles.tooltipText}>
                              Move to Ignored
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
            className={cx(styles.list)}
            style={{ height: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClusterList;
