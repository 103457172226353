import React from 'react';
import styles from './styles.module.scss';

interface ActionHeaderProps {
  showActionBasedOnPriority?: boolean; // Optional prop
  actionButtonType?: string | null;
  isFromCurrentCluster?: boolean; // Prop to toggle styles
}

const ActionHeader: React.FC<ActionHeaderProps> = ({
  showActionBasedOnPriority,
  actionButtonType,
  isFromCurrentCluster,
}) => {
  return (
    <div
      className={`${styles.dataHeader} ${isFromCurrentCluster ? styles.paddingStyles : styles.marginStyles}`}
    >
      <div className={styles.headerItemCheck}>Check</div>
      <div className={styles.headerItemStatus}>Status</div>
      {isFromCurrentCluster
        ? showActionBasedOnPriority &&
          actionButtonType && (
            <div className={styles.headerItemAction}>Action</div>
          )
        : actionButtonType && (
            <div className={styles.headerItemAction}>Action</div>
          )}
    </div>
  );
};

export default ActionHeader;
