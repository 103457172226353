import React from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';
import {
  ACTION_BUTTON_OPTIONS,
  DEVREV_ICON_VARIANTS,
  DEVREV_URL,
  JIRA_URL,
} from 'shared/constants';
import { Icon } from 'components/icon';

interface ActionButtonProps {
  actionButtonType: string | null; // Define allowed types
  iconStyle?: React.CSSProperties;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  actionButtonType,
  iconStyle,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const url =
      actionButtonType === ACTION_BUTTON_OPTIONS[0] ? JIRA_URL : DEVREV_URL;
    window.open(url, '_blank');
  };

  return (
    <div className={styles.actionButtonContainer}>
      <button className={styles.actionButton} onClick={handleClick}>
        <Icon
          className={cx(
            actionButtonType === ACTION_BUTTON_OPTIONS[0] && styles.icon,
            actionButtonType === ACTION_BUTTON_OPTIONS[1] && styles.icon_devrev,
          )}
          size={'1.5rem'}
          name={
            actionButtonType === ACTION_BUTTON_OPTIONS[0] ? 'Jira' : 'DevRev'
          }
        />
        {actionButtonType === ACTION_BUTTON_OPTIONS[1] && (
          <div className={styles.devRevIconContainer}>
            {DEVREV_ICON_VARIANTS.map((variant) => (
              <Icon
                key={variant}
                size="1.1rem"
                name="DevRev_multicolor"
                className={`${variant} ${styles.devRevIcon}`}
                style={iconStyle}
              />
            ))}
          </div>
        )}
      </button>
      <span className={styles.tooltipText}>
        {actionButtonType === ACTION_BUTTON_OPTIONS[0]
          ? 'Create Jira ticket'
          : 'Create DevRev ticket'}
      </span>
    </div>
  );
};

export default ActionButton;
