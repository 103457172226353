import React, { useEffect, useState } from 'react';
import { Icon } from 'components';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';
import styles from './styles.module.scss';
import cx from 'classnames';
import { ProgressSpinner } from 'primereact/progressspinner';

interface IgnoredCluster {
  label: string;
  value: string;
  failedCount: number;
  errorCount: number;
}

interface IgnoredClustersDropdownProps {
  ignoredClusters: IgnoredCluster[];
  onMoveUpClick: (cluster: IgnoredCluster) => void;
  loadingClusterMoveUp: string | null;
}

const IgnoredClustersDropdown: React.FC<IgnoredClustersDropdownProps> = ({
  ignoredClusters,
  onMoveUpClick,
  loadingClusterMoveUp,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  // Automatically expand the dropdown if there are ignored clusters
  useEffect(() => {
    if (ignoredClusters.length > 0) {
      setIsOpen(true); // Set to true when there is at least one ignored cluster
    } else {
      setIsOpen(false); // Optionally close if there are no ignored clusters
    }
  }, [ignoredClusters]); // Run this effect when ignoredClusters changes

  const toggleDropdown = () => setIsOpen(!isOpen);

  const iconStyle = {
    color: isOpen ? '#717e90' : 'none',
  };

  return (
    <div className={styles.dropdownWrapper}>
      <button onClick={toggleDropdown} className={styles.dropdownButton}>
        Ignored
        {isOpen ? (
          <div className={styles.dropdownIcon}>
            <ChevronUpIcon style={iconStyle} />
          </div>
        ) : (
          <div className={styles.dropdownIcon}>
            <ChevronDownIcon style={iconStyle} />
          </div>
        )}
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          {ignoredClusters.map((cluster, index) => (
            <div
              key={index}
              className={cx(styles.itemList, styles.link, {
                [styles.even]: index % 2 === 0, // Apply even class for even index
              })}
              onMouseEnter={() => setHoveredItem(cluster.label)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <div className={styles.itemContainer}>
                <h6 className={styles.name}>{cluster.label}</h6>
                <div className={styles.data}>
                  <div className={styles.item}>
                    <Icon
                      className={cx(styles.icon, styles.fail)}
                      size={'1.5rem'}
                      name="fail"
                    />
                    {cluster.failedCount}
                  </div>
                  <div className={styles.item}>
                    <Icon
                      className={cx(styles.icon, styles.error)}
                      size={'1.5rem'}
                      name="error"
                    />
                    {cluster.errorCount}
                  </div>
                  {hoveredItem === cluster.label && ( // Only render this div if hovered
                    <div className={styles.moveUpItem}>
                      {loadingClusterMoveUp === cluster.label ? (
                        <div className={styles.loadingContent}>
                          <ProgressSpinner
                            style={{ width: '1.5rem', height: '1.5rem' }}
                          />
                        </div>
                      ) : (
                        <div className={styles.arrowContainer}>
                          <button
                            className={styles.moveupButton}
                            onClick={() => onMoveUpClick(cluster)}
                          >
                            <Icon
                              className={cx(styles.icon, styles.arrow, {
                                [styles.fade]: hoveredItem === cluster.label,
                              })}
                              size={'1.5rem'}
                              name="arrow_circle_up"
                            />
                          </button>
                          {/* Show tooltip only when hovering over the button */}
                          <span className={styles.tooltipText}>Move up</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IgnoredClustersDropdown;
